<template>
  <div id="page_not_found">
    <section class="bg-cl-secondary py35 px20">
      <div class="container">
        <h2>
          {{ $t("Something went wrong ...") }}
        </h2>
      </div>
    </section>
    <section class="bg-cl-primary py35 px20">
      <div class="container">
        <div class="lh16 h5 weight-400">
          <p>
            {{ $t("We've noticed Internal Server Error while rendering this request.") }}
          </p>
          <p>
            {{ $t('If you need an assistance you can drop us a line on') }}
            <router-link :to="localizedRoute({ name: 'contacts' })" class="cl-secondary no-underline">
              {{ $t('a contact page') }}
            </router-link>
          </p>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-6 px40 py40">
            <button-outline
              color="dark"
              @click.native="$router.push('/')"
            >
              {{ $t('Return to Homepage') }}
            </button-outline>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Error from '@vue-storefront/core/pages/Error'
import ButtonOutline from 'theme/components/theme/ButtonOutline'

export default {
  name: 'Error',
  mixins: [Error],
  components: {
    ButtonOutline
  },
  methods: {
    redirectLink () {
      console.log('redirect')
    }
  }
}
</script>

<style scoped>
  a {
    text-decoration: underline;
  }
</style>
